
<script>
import Layout from "../../layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import store from "@/state/store";
import Switches from "vue-switches";
import MapComponent from "./map.vue";

var state = store._modules.root._children.auth.state;
export default {
  page: {
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
    Switches,
    MapComponent,
  },
  data() {
    return {
      Areas: [],
      types: [
        { name: "city", id: 1 },
        { name: "hood", id: 1 },
      ],
      parent_id: null,
      page: 1,
      limit: 20,
      tot_pages: 0,
      searchAreas: null,
      AreasSearchMode: false,
      searchModel: "",
      state: state,
      locationsArray: [],
    };
  },
  methods: {
    addAreas() {
      console.log("**********####", this.state);
      var data = {
        title: "popups.addAreas",
        inputs: [
          {
            model: "parent_id",
            type: "select",
            options: (() => {
              return this.Areas.map((data) => {
                return { value: data.id, label: data.name };
              });
            })(),
            label: "areas.type",
          },
          { model: "name", type: "text", label: "areas.name" },
          {
            model: "type",
            type: "select",
            options: (() => {
              return this.types.map((data) => {
                return { value: data.id, label: data.name };
              });
            })(),
            label: "areas.type",
          },
        ],
        buttons: [
          {
            text: "popups.add",
            closer: true,
            color: "primary",
            handler: (obj) => {
              this.http.post("areas", obj).then(() => {
                this.get(this.page);
              });
            },
          },
          {
            text: "popups.cancel",
            closer: true,
            color: "danger",
            handler: () => {
              console.log("Like Clicked");
            },
          },
        ],
      };
      this.popup.modal(data);
    },

    switchStatus(app) {
      // this.status = !this.status
      app.active = !app.active;
      this.http.put("areas", app?.id, { status: app.active }).then((res) => {
        if (res.status) {
          this.get(this.page);
        }
      });
    },
    editAreas(app) {
      var data = {
        title: "popups.areas",
        inputs: [
          {
            model: "parent_id",
            type: "text",
            label: "areas.parent_id",
            value: app.parent_id,
          },
          { model: "name", type: "text", label: "areas.name", value: app.name },
          { model: "type", type: "text", label: "areas.type", value: app.type },
          {
            model: "latitude",
            type: "text",
            label: "areas.latitude",
            value: app.latitude,
          },
          {
            model: "longitude",
            type: "text",
            label: "areas.longitude",
            value: app.longitude,
          },
          {
            model: "active",
            type: "text",
            label: "areas.active",
            value: app.active,
          },
        ],
        buttons: [
          {
            text: "popups.edit",
            closer: true,
            color: "primary",
            handler: (obj) => {
              this.http.put("areas", app.id, obj).then(() => {
                this.get(this.page);
              });
            },
          },
          {
            text: "popups.cancel",
            closer: true,
            color: "danger",
            handler: () => {
              console.log("Like Clicked");
            },
          },
        ],
      };
      this.popup.modal(data);
    },
    updateAreas(app) {
      var data = {
        title: "popups.editfile",
        inputs: [
          { model: "parent_id", type: "text", label: "areas.parent_id" },
          { model: "name", type: "text", label: "areas.name" },
          {
            model: "city",
            type: "select",
            options: (() => {
              return this.types.map((data) => {
                return { value: data.id, label: data.name };
              });
            })(),
            label: "areas.type",
          },
          { model: "latitude", type: "text", label: "areas.latitude" },
          { model: "longitude", type: "text", label: "areas.longitude" },
          { model: "active", type: "text", label: "areas.active" },
        ],
        buttons: [
          {
            text: "popups.edit",
            closer: true,
            color: "primary",
            handler: (obj) => {
              obj.emp_id = app.id;
              this.http.do("areas", obj).then(() => {
                this.get(this.page);
              });
            },
          },
          {
            text: "popups.cancel",
            closer: true,
            color: "danger",
            handler: () => {
              console.log("Like Clicked");
            },
          },
        ],
      };
      this.popup.modal(data);
    },
    search() {
      this.AreasSearchMode = true;
      this.tot_pages = 0;
      this.http
        .post("areas/search", {
          search: this.searchModel,
          limit: this.limit,
          page: this.page,
          col: "",
        })
        .then((res) => {
          this.Areas = res.data;
          console.log("######## files", this.Areas);
        });
    },
    cancelappsearchMode() {
      this.searchModel = "";
      this.AreasSearchMode = false;
      this.get(this.page);
    },

    getAreas() {
      this.http
        .post("areas/district", { parent_id: this.parent_id })
        .then((res) => {
          let data = res.data;
          for (let i = 0; i < data.length; i++) {
            this.locationsArray.push({
              lat: data[i]?.latitude,
              lng: data[i]?.longitude,
              label: data[i]?.name,
              description: "Capital of Saudi Arabia",
            });
          }
          console.log(265, this.locationsArray);
        });
    },
    deleteAreas(app) {
      var data = {
        title: "popups.deletefile",
        msg: "popups.delmessage",
      };
      this.popup.confirm(data).then((resp) => {
        if (resp) {
          console.log("###");
          this.http.delete("areas", app.id).then(() => {
            console.log("############################3");
            this.get(this.page);
          });
        }
      });
    },

    get(page) {
      console.log(page);

      this.http
        .post("areas/paginate-district", {
          limit: this.limit,
          page: page,
          parent_id: this.parent_id,
        })
        .then((res) => {
          this.page = page;
          this.tot_pages = Math.ceil(res.tot / this.limit);
          this.Areas = res.data;
        });
    },
  },
  created() {
    this.parent_id = this.$route.params.id;
    this.get(1);
    this.getAreas();
  },
};
</script>

<template>
  <Layout>
    <PageHeader
      :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
      :title="$t('menu.menuitems.areas.text')"
    />

    <div class="row">
      <b-tabs
        content-class="p-4"
        class="pt-2"
        nav-wrapper-class="nav-item"
        nav-class="justify-content-start nav-tabs-custom"
      >
        <b-tab class="col" title="Areas Data Table">
          <div class="row">
            <div class="col-6">
              <div class="search-box chat-search-box w-50">
                <div class="position-relative">
                  <input
                    @keyup="search()"
                    type="text"
                    class="form-control fa-lg text-light"
                    :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
                    :placeholder="$t('popups.search')"
                    v-model="searchModel"
                    style="background-color: #2a3042 !important"
                  />
                  <i class="bx bx-search-alt search-icon text-light"></i>
                </div>
              </div>
            </div>
            <div class="col-6" style="display: flex; justify-content: flex-end">
              <button
                class="btn-close text-light"
                v-if="AreasSearchMode"
                @click="cancelappsearchMode()"
              ></button>
              <button
                type="button"
                @click="addAreas()"
                class="btn btn-light float-end mb-4"
              >
                <span
                  class="bx bxs-plus-circle float-end fa-2x"
                  style="color: #2a3042 !important"
                ></span>
              </button>
            </div>
          </div>

          <table :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'" class="table">
            <thead>
              <tr
                class="text-light text-center"
                style="background-color: #2a3042 !important"
              >
                <th scope="col">{{ $t("areas.id") }}</th>
                <th scope="col">{{ $t("areas.name") }}</th>
                <th scope="col">{{ $t("areas.type") }}</th>
                <th scope="col">{{ $t("areas.latitude") }}</th>
                <th scope="col">{{ $t("areas.longitude") }}</th>
                <th scope="col">{{ $t("areas.active") }}</th>
                <th scope="col">{{ $t("areas.operations") }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(app, index) in Areas" :key="app" class="text-center">
                <td>{{ index + 1 }}</td>
                <td>{{ app.name }}</td>
                <td>{{ app.type }}</td>
                <td>{{ app.latitude }}</td>
                <td>{{ app.longitude }}</td>

                <td colspan="1">
                  <switches
                    @change="switchStatus(app)"
                    :value="app.active"
                    color="success"
                    class="ms-1"
                  ></switches>
                </td>

                <td class="d-flex">
                  <router-link
                    class="btn btn-success mx-1"
                    :to="'/areas-district-available-days/' + app.id"
                    href="javascript: void(0);"
                    role="button"
                    >{{ $t("popups.workdays") }}
                  </router-link>
                  <a
                    class="btn btn-primary mx-1"
                    @click="editAreas(app)"
                    href="javascript: void(0);"
                    role="button"
                    >{{ $t("popups.edit") }}</a
                  >
                  <a
                    class="btn btn-danger"
                    href="javascript: void(0);"
                    role="button"
                    @click="deleteAreas(app)"
                    >{{ $t("popups.delete") }}</a
                  >
                </td>
              </tr>
            </tbody>
          </table>
          <!-- end  table -->
          <!-- Button trigger modal -->

          <div v-if="tot_pages > 0">
            <ul class="paginate paginate-rounded justify-content-center mb-2">
              <!-- Previous Page Link -->
              <li class="page-item" :class="{ disabled: page === 1 }">
                <a
                  class="page-link"
                  href="javascript: void(0);"
                  @click="get(page - 1)"
                  aria-label="Previous"
                >
                  <i class="mdi mdi-chevron-left"></i>
                </a>
              </li>

              <!-- Page Number Links -->
              <li
                class="page-item"
                :class="{ active: p === page }"
                v-for="p in totalPages"
                :key="p"
              >
                <a
                  class="page-link"
                  href="javascript: void(0);"
                  @click="get(p)"
                  >{{ p }}</a
                >
              </li>

              <!-- Next Page Link -->
              <li class="page-item" :class="{ disabled: page === totalPages }">
                <a
                  class="page-link"
                  href="javascript: void(0);"
                  @click="get(page + 1)"
                  aria-label="Next"
                >
                  <i class="mdi mdi-chevron-right"></i>
                </a>
              </li>
            </ul>
          </div>
        </b-tab>
        <b-tab title="Districts Map view" class="fa fa-map">
          <MapComponent :locations="locationsArray" />
        </b-tab>
      </b-tabs>
    </div>
    <!--end-->
  </Layout>
</template>

<style scoped>
.btn-close {
  position: relative;
  right: 317px;
  top: 6px;
  font-size: 15px;
}

.btn-close {
  position: relative;
  right: 317px;
  top: 6px;
  font-size: 15px;
}

.paginate {
  display: flex;
  justify-content: center;
  padding-left: 0;
  list-style: none;
}

.page-item {
  display: inline-block;
}

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #007bff;
  background-color: #fff;
  border: 1px solid #dee2e6;
  text-decoration: none;
}

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  background-color: #fff;
  border-color: #dee2e6;
}

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
</style>




